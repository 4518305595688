
import { defineComponent } from "vue";
import store from "@/store";
import axios from "axios";
import moment from "moment";
import * as Yup from "yup";
import { ElMessage } from "element-plus";
import { useField, useForm } from "vee-validate";

export default defineComponent({
  name: "customers-listing",
  components: {
    //Datatable,
  },
  data() {
    return {
      profileUrl: "https://php.rankopen.com/plugin.php?id=api&mode=pub&tab=image&uid=" + this.$route.params.uid,
      page: Number(this.$route.params.page),
      doublePage: Number(this.$route.params.page),
      type: "single",
      recentGameSingle: [],
      recentGameDouble: [],
      singleRecord: [],
      doubleRecord: [],
      singleMaxPage: 1,
      doubleMaxPage: 1,
      tourName: [],
      tourDoubleName: [],
      groupName: [],
      groupDoubleName: [],
      playerData: [],
      playerid: "",
    };
  },
  mounted() {
    if (!this.$route.params.type) {
      this.$router.replace({
        name: "user",
        params: { uid: this.$route.params.uid, type: "single", page: 1 },
      });
    }
    if (this.$route.params.type == "single") {
      this.getSingleData();
    } else if (this.$route.params.type == "double") {
      this.getDoubleData();
    }
    this.getUserData();
  },
  methods: {
    getUserData() {
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=pub&tab=user&uid=" + this.uid,
          JSON.stringify({ lang: this.$i18n.locale, admin: store.state.uid })
        )
        .then((response) => {
          this.playerData = response.data.playerData;
          this.playerData['highRankDate'] = moment(this.playerData['highRankDate']).format('YYYY')
          this.playerData['doubleHighRankDate'] = moment(this.playerData['doubleHighRankDate']).format('YYYY')
          this.playerid = response.data.playerData.player_id;
        });
    },
    getSingleData() {
      axios
        .post(
          process.env.VUE_APP_API_URL +
            "&mode=pub&tab=user&uid=" +
            this.uid +
            "&type=single" +
            "&page=" +
            this.$route.params.page,
          JSON.stringify({ lang: this.$i18n.locale })
        )
        .then((response) => {
          this.recentGameSingle = response.data.recentGameSingle;
          this.singleRecord = response.data.singleRecord;
          this.groupName = response.data.groupName;
          this.tourName = response.data.tourName;
          this.singleMaxPage = response.data.singleMaxPage;
        });
    },
    getDoubleData() {
      axios
        .post(
          process.env.VUE_APP_API_URL +
            "&mode=pub&tab=user&uid=" +
            this.uid +
            "&type=double" +
            "&page=" +
            this.$route.params.page,
          JSON.stringify({ lang: this.$i18n.locale })
        )
        .then((response) => {
          this.recentGameDouble = response.data.recentGameDouble;
          this.groupDoubleName = response.data.groupName;
          this.tourDoubleName = response.data.tourName;
          this.doubleRecord = response.data.doubleRecord;
          this.doubleMaxPage = response.data.doubleMaxPage;
        });
    },
    imageLoadError() {
      this.profileUrl =
        "https://php.rankopen.com/uc_server/data/avatar/image/default.png?version=1";
    },
  },
  computed: {
    uid() {
      return this.$route.params.uid;
    },
    detectPage() {
      return Number(this.$route.params.page);
    },
    detectType() {
      return this.$route.params.type;
    },
  },
  watch: {
    page() {
      // When change of single and double
      this.$router.push(
        {name: 'user', params: { uid: this.uid, type: this.$route.params.type, page: this.page}}
      );
    },
    doublePage() {
      // When change of single and double
      this.$router.push(
        {name: 'user', params: { uid: this.uid, type: this.$route.params.type, page: this.doublePage}}
      );
    },
    detectPage() {
      if (this.$route.params.type == "single") {
        this.getSingleData();
        this.page = Number(this.$route.params.page);
      } else if (this.$route.params.type == "double") {
        this.getDoubleData();
        this.doublePage = Number(this.$route.params.page);
      }
    },
    detectType() {
      if (this.$route.params.type == "single") {
        this.getSingleData();
        this.page = Number(this.$route.params.page);
      } else if (this.$route.params.type == "double") {
        this.getDoubleData();
        this.doublePage = Number(this.$route.params.page);
      }
    },
  },
});
