
import { defineComponent } from "vue";
import userPage from "@/views/pages/user/userpage.vue";

export default defineComponent({
  name: "user",
  components: {
    userPage
  },
});
